import React from "react"
import { Box , Flex} from "@sqymagma/elements"

export default ({ src1, alt1 = "", src2, alt2 = "" }) => {
  return (
    <Flex width={1} my="m">
        <Box width={["100%", "50%"]} mr={["0", "6%"]}>
            <img src={src1} alt={alt1} />
        </Box>
        <Box width={["100%", "50%"]} mr={["0", "6%"]}>
            <img src={src2} alt={alt2} />
        </Box>
    </Flex>
  )
}