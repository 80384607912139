import React from "react";
import LayoutDay from "../../../components/Chronology/LayoutDay";
import ContentLeft from "../../../components/Chronology/Modules/ContentLeft";
import ContentRight from "../../../components/Chronology/Modules/ContentRight";
import ModCCAATable from "../../../components/Chronology/Modules/ModCCAATable";
import ModText from "../../../components/Chronology/Modules/ModText";
import InlineLink from "../../../components/Chronology/Modules/InlineLink";
import ModImage from "../../../components/Chronology/Modules/ModImage";
import ModReferenceList from "../../../components/Chronology/Modules/ModReferenceList";
import ReferenceRow from "../../../components/Chronology/Modules/ReferenceRow";
import ModAnimation from "../../../components/Chronology/Modules/ModAnimation";
import ModCovidHighlight from "../../../components/Chronology/Modules/ModCovidHighlight";
import ModDataEu from "../../../components/Chronology/Modules/ModDataEu";
import ModLethalityEu from "../../../components/Chronology/Modules/ModLethalityEu";
import ModDottedLine from "../../../components/Chronology/Modules/ModDottedLine";
import ModTwoImgCols from "../../../components/Chronology/Modules/ModTwoImgCols";

export const frontmatter = {
  title: "Día 04",
  week: "Semana 1",
  month: "Mar",
  day: "17",
  monthNumber: "03",
  date: "2020-03-17",
  path: "/cronologia/semana-01#dia-17-mar",
};

const Day04 = (props) => {
  return (
    <LayoutDay frontmatter={frontmatter} {...props}>
      <ContentLeft>
        <ModCCAATable fecha={frontmatter.date} />
        <ModDottedLine />
        <ModDataEu fecha={frontmatter.date} />
        <ModLethalityEu fecha={frontmatter.date} numPaises={8} />
        <ModCovidHighlight>
          <strong>Ucrania</strong> declara los primeros casos confirmados de
          COVID-19 .
        </ModCovidHighlight>
      </ContentLeft>

      <ContentRight>
        <ModText>
          En <strong>España</strong>, el balance del día se salda con el
          registro de 1.987 nuevos positivos y 182 personas fallecidas.
          Permanecen hospitalizadas 3.910 personas, 1.413 más que el día
          anterior, y 511 reciben el alta. El número de casos ingresados en la
          UCI asciende a 563, lo que supone un aumento de 114 personas. Respecto
          al número de casos totales confirmados, la tasa de mortalidad es del
          4,4 % y la tasa de recuperados del 9,7 %.
        </ModText>

        <ModText>
          El Consejo de Ministros ha aprobado un{" "}
          <strong>Real Decreto-ley</strong>{" "}
          <InlineLink link="https://www.lamoncloa.gob.es/consejodeministros/Paginas/enlaces/170320-enlace-medidas.aspx">
            de medidas urgentes extraordinarias
          </InlineLink>
          para hacer frente al impacto económico y social del COVID-19.
        </ModText>
        <ModImage
          src="https://coronavirus.secuoyas.com/svg/17_mar_mayores.svg"
          alt="ayuda a personas mayores"
        />
        <ModTwoImgCols
          src1="/images/svg/17_mar_proteccion_empleo.svg"
          alt1="protección del empleo"
          src2="/images/svg/17_mar_asegurar_liquidez_empresas.svg"
          alt2="asegurar liquidez"
        />
        <ModImage
          src="https://coronavirus.secuoyas.com/svg/17_mar_vacuna.svg"
          alt="recursos vacuna"
        />
        <ModText>
          Asimismo, también han publicado una actualización de las medidas
          establecidas por el estado de alarma (publicadas el pasado 14 de
          marzo).
        </ModText>
        <ModText>
          El<strong> Ministerio de Sanidad</strong> ha iniciado el reparto de
          más de un millón de mascarillas quirúrgicas entre las CCAA. Madrid es
          la comunidad que va a recibir un mayor número por tratarse también de
          la región que registra mayor número de positivos.
        </ModText>
        <ModAnimation svg="/images/anim/17_mar_ministerio_sanidad.svg" />

        <ModText>
          La AEMPS (Agencia Española de Medicamentos y Productos Sanitarios),
          organismo dependiente del Ministerio de Sanidad) está trabajando en
          diferentes vías de investigación clínica para el tratamiento del
          COVID-19.
        </ModText>

        <ModReferenceList title="Guías y documentos publicados">
          <ReferenceRow
            link="https://www.lamoncloa.gob.es/consejodeministros/Paginas/enlaces/170320-enlace-medidas.aspx"
            name=" Medidas urgentes extraordinarias"
          />
          <ReferenceRow
            link="https://www.lamoncloa.gob.es/consejodeministros/Paginas/enlaces/170320-enlace-alarma.aspx"
            name=" Actualización de medidas <strong>estado de alarma</strong> (14 marzo)"
          />
        </ModReferenceList>
      </ContentRight>
    </LayoutDay>
  );
};

export default Day04;
